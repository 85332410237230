  <template>
  <div class="trainingWrap container">
    <header>
      <headerTop></headerTop>
      <navTab></navTab>
    </header>
    <div class="main-content">
      <div class='swiperBox'>
        <el-carousel trigger="click" ref="swiperCard" height="552px"   :interval="5000" arrow="hover" @change='changeImg'>
          <el-carousel-item v-for="(item,index) in swiperList" :key="index" @click.native="$router.push(`/trainDynamicInfo/${swiperList[currentIndex].id}`)">
            <div>
              <img  :src="item.image_url" style="width: 934px;height: 552px;"/>
            </div>
          </el-carousel-item>
        </el-carousel>
        <ul class='swiperTextBox'>
          <li class='swiperTextItem' @click='userChangeImg(index)' :class="{'lightTextItem':currentIndex==index}" v-for="(item,index) in swiperList" :key="index" >
            <div class='title'>{{item.title}}</div>
            <div class='date'>{{item.createTime.split(' ')[0]}}</div>
          </li>
        </ul>
      </div>

       <ul class='pxdtList'>
        <li class='pxdtItem' v-for="(item,index) in recordsData" :key='index' @click="$router.push(`/trainDynamicInfo/${item.id}`)">
          <div class='cover'>
            <img :src="item.image_url" alt="" srcset="">
          </div>
          <div class='time'>
            <div class='m_d'>{{item.createTime.split(' ')[0].split('-')[1]+'-'+item.createTime.split(' ')[0].split('-')[2]}}</div>
            <div>{{item.createTime.split(' ')[0].split('-')[0]}}</div>
          </div>
          <div class='content'>
            <div class='title'>
              {{item.title}}
            </div>
            <div class='description'>
              {{item.subtitle}}
            </div>
          </div>
        </li>
       </ul>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
import headerTop from '@/components/header.vue'
import navTab from '@/components/navTab.vue'
export default {
  name: '',
  components: {
    footerCom,
    headerTop,
    navTab
  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      currentIndex: 0,
      recordsData: [],
      swiperList: [],
      bannerList: []
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    getPxdtQueryPage () {
      let reqOption = {
        'pageNo': 1,
        'pageSize': 999,
        'params': { cmsCategoryId: '1626387088595279873' }
      }
      console.log(this.$apiUrl.queryStudentCoursePage)
      this.$axios.post(this.$apiUrl.pxdtQueryPage, reqOption).then(res => {
        console.log(res)
        let {records} = res.data.data
        this.recordsData = JSON.parse(JSON.stringify(records))
        console.log(this.swiperList)
        if (this.recordsData.length>5) {
          this.swiperList = JSON.parse(JSON.stringify(records)).splice(0, 5)
        } else {
          this.swiperList = JSON.parse(JSON.stringify(records))
        }
      })
    },
    changeImg (index) {
      this.currentIndex = index
    },
    userChangeImg (index) {
      this.currentIndex = index
      this.$refs.swiperCard.setActiveItem(index)
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
  },
  mounted () {
    this.getPxdtQueryPage()
    this.getUserInfo()
  },
}
</script>

<style scoped lang="scss">

@keyframes xing {
    100% {
        -webkit-transform: scale(0.5);
        opacity: 0
    }
    130% {
        -webkit-transform: scale(1);
        opacity: 1
    }
}
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1240px;
    margin: 0 auto;
    line-height: 56px;
    /* padding: 0 100px; */

    .header-left-content {
      font-size: 22px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 42px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 34px;
        line-height: 34px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      ::v-deep .el-dropdown {
        font-size: 18px;
        color: #4774df;
        background-color: #fff;
        height: 34px;
        line-height: 34px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }

  .nav_side {
    width: 1240px;
    margin: 0 auto;
    height: 61px;
    line-height: 61px;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 22px;
      font-weight: bold;
    }

    nav a {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 61px;
    }

    nav a:hover {
      opacity: 1;
    }

    .active {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      border-bottom: 3px solid #ffffff;
    }
  }
}
  .main-content {
    width: 1240px;
    padding-bottom: 188px;
    margin:  0 auto 0;
    padding-bottom: 188px;
    background-color: transparent;
    .swiperBox{
      display: flex;
      margin-bottom: 58px;
      .swiperTextBox{
        width: 372px;
        .swiperTextItem{
           width: 372px;
          height: 110px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: #2F3A4A;
          color: #FFFFFF;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          border-bottom: 1px solid #707070;
          cursor: pointer;
          .title{
            /* height: 56px;
            width: 332px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all; */
            font-size: 16px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .date{
            font-size: 17px;
          }
        }
        .lightTextItem{
          background-color: #4774DF;
          position: relative;
          transform: scale(1.1);
          z-index: 999999;
        }
      }
    }
    .pxdtList{
      margin-bottom: 90px;
      .pxdtItem{
        display: flex;
        border-bottom: 2px solid #1C4ECC;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 20px;
        .cover{
          width:207px;
          height: 132px;
          img{
            width:207px;
            height: 132px;
          }
        }
        .time{
          width: 100px;
          margin: 0px 21px 0px 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          border-right: 1px solid #cecece;
          .m_d{
            font-size: 22px;
          }
        }
        .content{
          flex: 1;
          font-size: 20px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          .title{
            margin-bottom: 23px;
          }
          .description{
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #000000;
          }
        }
      }

    }
  }
  ::v-deep .el-carousel{
    width: 934px
  }
</style>
